import { TranslationService } from '@asol/core';
import { TRANS } from './../../constants/translation.constant';
import { Component, OnInit } from '@angular/core';
import translation from './main-page-cwo.translation.json';

@Component({
  selector: 'app-main-page-cwo',
  templateUrl: './main-page-cwo.component.html',
  styleUrls: ['./main-page-cwo.component.scss'],
})
export class MainPageCwoComponent implements OnInit {
  TRANS = TRANS;

  constructor(public trans: TranslationService) {
    this.trans.initialize(TRANS.MAIN_PAGE, translation);
  }

  ngOnInit(): void {}
}
