import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslationService } from '@asol/core';
import { TRANS } from '../../constants/translation.constant';
import translation from './forgot-password.translation.json';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  TRANS = TRANS;

  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public trans: TranslationService
  ) {
    this.trans.initialize(TRANS.FORGOT_PASSWORD, translation);
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
}
