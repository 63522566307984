import { Component } from '@angular/core';
import { TranslationService } from '@asol/core';
import { TRANS } from '../../constants/translation.constant';
import translation from './main-page.translation.json';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent {
  TRANS = TRANS;

  constructor(public trans: TranslationService) {
    this.trans.initialize(TRANS.MAIN_PAGE, translation);
  }
}
