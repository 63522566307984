<div class="content d-flex justify-content-center">
  <div>
    <app-main-page></app-main-page>
  </div>
  <div class="form-container">
    <app-language-switcher></app-language-switcher>
    <div class="form-header">
      {{ trans.get(TRANS.CREATE_PASSWORD, 'PasswordCreation') }}
    </div>
    <div class="form-description">
      {{ trans.get(TRANS.CREATE_PASSWORD, 'PasswordConstraints') }}
    </div>
    <div class="form">
      <form [formGroup]="form">
        <div class="w-100">
          <asol-input-field
            inputType="password"
            formControlName="password"
            [transKey]="TRANS.CREATE_PASSWORD"
            labelKey="Password"
            placeholderKey="Password"></asol-input-field>
        </div>
        <div class="w-100">
          <asol-input-field
            inputType="password"
            formControlName="passwordConfirmation"
            [transKey]="TRANS.CREATE_PASSWORD"
            labelKey="PasswordConfirmation"
            placeholderKey="PasswordConfirmation"></asol-input-field>
        </div>
        <div class="row buttons">
          <button type="button" class="btn btn-primary btn-lg btn-block">
            {{ trans.get(TRANS.CREATE_PASSWORD, 'RefreshPasswordButton') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div id="progress-container"></div>
