import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { TranslateService } from '@ngx-translate/core';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TitanRegistrationServiceBaseResponse } from '../model/titan-registration-service-base-response.interface';
import { BaseDataService } from './base-data-service.model';
import {
  ErrorTranslationUtils,
  ReturnCodeEnum,
} from './error-translation-util';

@Injectable({
  providedIn: 'root',
})
export class ApiDataService extends BaseDataService {
  private apiBaseUrl: string;

  constructor(
    private httpClient: HttpClient // protected translate: TranslateService
  ) {
    super();
    const u = new URL(environment.API_URL);
    this.apiBaseUrl = u.toString() + '/';
  }

  public get<TRead>(uri: string): Observable<TRead> {
    const parameters = this.createHttpParams();

    return this.httpClient
      .get<TRead>(`${this.apiBaseUrl}${uri}`, {
        headers: this.getHeaders(),
        params: parameters,
      })
      .pipe(
        catchError(this.handleError),
        map((resp) => {
          return this.processResponse(resp);
        })
      );
  }

  public getOne<TRead>(uri: string, searchParams?: unknown): Observable<TRead> {
    const parameters = this.createHttpParams(searchParams);

    return this.httpClient
      .get<TRead>(`${this.apiBaseUrl}${uri}`, {
        headers: this.getHeaders(),
        params: parameters,
        observe: 'body',
      })
      .pipe(
        catchError(this.handleError),
        map((resp) => {
          return this.processResponse(resp);
        })
      );
  }

  public postData<TReturn>(uri: string, data: unknown): Observable<TReturn> {
    return this.httpClient
      .post<TReturn>(`${this.apiBaseUrl}${uri}`, data, {
        headers: this.getHeaders(),
        observe: 'body',
      })
      .pipe(
        catchError(this.handleError),
        map((resp) => {
          return this.processResponse(resp);
        })
      );
  }

  public put<TReturn>(uri: string, data: unknown): Observable<TReturn> {
    return this.httpClient
      .put<TReturn>(`${this.apiBaseUrl}${uri}`, data, {
        headers: this.getHeaders(),
        observe: 'body',
      })
      .pipe(
        catchError(this.handleError),
        map((resp) => {
          return this.processResponse(resp);
        })
      );
  }

  public delete<TDelete>(
    uri: string,
    id: string | number
  ): Observable<TDelete> {
    return this.httpClient
      .delete<TDelete>(`${this.apiBaseUrl}${uri}` + '/' + id, {
        headers: this.getHeaders(),
        observe: 'body',
      })
      .pipe(
        catchError(this.handleError),
        map((resp) => {
          return this.processResponse(resp);
        })
      );
  }

  public deleteData<TDelete>(uri: string, data: unknown): Observable<TDelete> {
    const parameters = this.createHttpParams(data);

    return this.httpClient
      .delete<TDelete>(`${this.apiBaseUrl}${uri}`, {
        headers: this.getHeaders(),
        observe: 'body',
        params: parameters,
      })
      .pipe(
        catchError(this.handleError),
        map((resp) => {
          return this.processResponse(resp);
        })
      );
  }

  public post<TWrite>(uri: string): Observable<TWrite> {
    return this.httpClient
      .post<TWrite>(`${this.apiBaseUrl}${uri}`, {
        headers: this.getHeaders(),
        observe: 'body',
      })
      .pipe(
        catchError(this.handleError),
        map((resp) => {
          return this.processResponse(resp);
        })
      );
  }

  protected handleError(error: HttpErrorResponse) {
    console.error('ERROR_HANDLER: ', error);
    let msg =
      error.error?.message ??
      error.error ??
      (error as HttpErrorResponse).statusText;
    if (msg instanceof ProgressEvent) {
      msg = (msg as ProgressEvent).type;
    }
    if (typeof msg === 'object') {
      msg = JSON.stringify(msg);
    }
    DialogUtility.alert({
      title: 'WARNING',
      position: { X: 'center', Y: 'center' },
      content: msg,
      cssClass: 'warning-dialog-class',
      showCloseIcon: true,
      closeOnEscape: true,
      animationSettings: { effect: 'Zoom' },
      zIndex: 99999999999999,
    }).show();
    return throwError(error.message);
  }

  public processResponse<T>(resp: TitanRegistrationServiceBaseResponse) {
    const returnMessage =
      resp as unknown as TitanRegistrationServiceBaseResponse;
    if (returnMessage.code && returnMessage.code != 0) {
      this.handleReturnCode(
        (resp as TitanRegistrationServiceBaseResponse).code
      );
      throw new Error(returnMessage.message);
    } else {
      return resp as T;
    }
  }

  protected handleReturnCode(stateId: ReturnCodeEnum) {
    DialogUtility.alert({
      // title: this.translate.instant('RETURN_CODE.WE_ARE_SORRY'),
      position: { X: 'center', Y: 'center' },
      content: ErrorTranslationUtils.trasnslateErrorState(
        stateId
        // this.translate
      ),
      cssClass: 'warning-dialog-class',
      showCloseIcon: true,
      closeOnEscape: true,
      animationSettings: { effect: 'Zoom' },
      zIndex: 99999999999999,
    }).show();
  }
}
