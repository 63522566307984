export const CONTENT_TYPE = {
  JSON: 'application/json',
  PLAIN: 'text/plain',
  CSS: 'text/css',
  HTML: 'text/html',
  JAVASCRIPT: 'text/javascript',
  OCTET_STREAM: 'application/octet-stream',
  IMAGE_ALL: 'image/*',
  IMAGE_GIF: 'image/gif',
  IMAGE_JPEG: 'image/jpeg',
  IMAGE_PNG: 'image/png',
  IMAGE_SVG: 'image/svg+xml',
  AUDIO_WEBM: 'audio/webm',
  AUDIO_OGG: 'audio/ogg',
  VIDEO_WEBM: 'video/webm',
  VIDEO_OGG: 'video/ogg',
  OGG: 'application/ogg',
  FORM_DATA: 'multipart/form-data',
  BYTE_RANGES: 'multipart/byteranges',
  WORD: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};
