<div class="h-25 language-selector">
  <div class="dropdown">
    <button
      title="lang"
      ejs-dropdownbutton
      type="button"
      (select)="changeLanguage($event)"
      [items]="[
        {
          id: 'en',
          text: trans.get(TRANS.LANGUAGE_SWITCHER, 'English')
        },
        {
          id: 'cs',
          text: trans.get(TRANS.LANGUAGE_SWITCHER, 'Czech')
        },
        {
          id: 'sk',
          text: trans.get(TRANS.LANGUAGE_SWITCHER, 'Slovak')
        }
      ]">
      <img
        *ngIf="
          trans.currentLocale == 'en' ||
          trans.currentLocale == 'en-GB' ||
          trans.currentLocale == 'en-US'
        "
        class="flag"
        src="/assets/icons/flags/en.svg"
        alt="flag" />
      <img
        *ngIf="trans.currentLocale == 'cs' || trans.currentLocale == 'cs-CZ'"
        class="flag"
        src="/assets/icons/flags/cs.svg"
        alt="flag" />
      <img
        *ngIf="trans.currentLocale == 'sk' || trans.currentLocale == 'sk-SK'"
        class="flag"
        src="/assets/icons/flags/sk.svg"
        alt="flag" />
      <label>{{ trans.get(TRANS.LANGUAGE_SWITCHER, language) }}</label>
    </button>
  </div>
</div>
