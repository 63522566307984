import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslationService } from '@asol/core';
import { TRANS } from '../../constants/translation.constant';
import translation from './login-screen.translation.json';
import { Login } from './models/login.interface';

@Component({
  selector: 'app-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.scss'],
})
export class LoginScreenComponent implements OnInit {
  TRANS = TRANS;

  public form: FormGroup;

  loginTypes: Login[] | null = [];

  constructor(
    private formBuilder: FormBuilder,
    public trans: TranslationService
  ) {
    this.trans.initialize(TRANS.LOGIN, translation);
    this.loginTypes = [{ type: 'Easy Auth' }];
    this.form = this.formBuilder.group({
      loginType: [this.loginTypes[0].type],
    });
  }

  ngOnInit(): void {
    this.loginTypes = [{ type: 'Easy Auth' }];
    this.form.patchValue({ loginType: this.loginTypes[0].type });
  }
}
