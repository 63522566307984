import { Component, OnInit } from '@angular/core';
import { TranslationService } from '@asol/core';
import translation from './language-switcher.translation.json';
import { TRANS } from '../../constants/translation.constant';
import { MenuEventArgs } from '@syncfusion/ej2-navigations';
import { setCulture } from '@syncfusion/ej2-base';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent implements OnInit {
  flagSource = '';
  language = '';

  TRANS = TRANS;

  constructor(public trans: TranslationService) {}

  ngOnInit(): void {
    this.trans.initialize(TRANS.LANGUAGE_SWITCHER, translation);
    this.changeLanguage({
      item: {
        id: navigator.language,
      },
    } as MenuEventArgs);
  }

  changeLanguage(event: MenuEventArgs): void {
    const language = event.item.id;
    this.trans.setLocale(language);
    switch (language) {
      case 'en':
      case 'en-GB':
      case 'en-US':
        this.language = 'English';
        this.flagSource = '/assets/images/usa_flag.png';
        break;
      case 'cs':
      case 'cs-CZ':
        this.language = 'Czech';
        this.flagSource = '/assets/images/cze_flag.png';
        break;
      case 'sk':
      case 'sk-SK':
        this.language = 'Slovak';
        this.flagSource = '/assets/images/svk_flag.png';
        break;
    }
  }
}
