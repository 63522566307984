import { UserService } from './user.service';
import { TRANS } from '../constants/translation.constant';
import { TranslationService } from '@asol/core';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { APP_CODE } from '../constants/app-code.constant';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import translation from '../../app.translation.json';

@Injectable({
  providedIn: 'root',
})
export class TokenRequiredService {
  token = new BehaviorSubject<string>(null);

  constructor(
    private route: ActivatedRoute,
    private trans: TranslationService,
    private userService: UserService
  ) {
    this.trans.initialize(TRANS.GENERAL, translation);
    this.route.queryParamMap.subscribe((params) => {
      if (
        environment.AppCode != APP_CODE.CWO &&
        environment.AppCode != APP_CODE.TITANGUESTREGCLOUD
      )
        return;

      if (!params.has('t')) {
        this.dialog(this.trans.get(TRANS.GENERAL, 'tokenWarningMessage'));
      } else {
        const token = params.get('t');
        switch (environment.AppCode) {
          case APP_CODE.CWO:
          case APP_CODE.TITANGUESTREGCLOUD:
            this.userService.tokenValidate(token).subscribe((val) => {
              if (val == true) {
                this.token.next(token);
              } else {
                this.dialog(
                  this.trans.get(TRANS.GENERAL, 'invalidTokenMessage')
                );
              }
            });
            break;
          default:
            throw new Error('Unsupported application code!!!');
        }
      }
    });
  }

  private dialog(message: string): void {
    DialogUtility.alert({
      title: this.trans.get(TRANS.GENERAL, 'warn'),
      position: { X: 'center', Y: 'center' },
      content: message,
      cssClass: 'warning-dialog-class',
      showCloseIcon: false,
      closeOnEscape: false,
      animationSettings: { effect: 'Zoom' },
      zIndex: 99999999999999,
      isModal: true,
      close: () => {
        location.href = '/';
      },
    }).show();
  }
}
