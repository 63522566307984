import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { TranslationService } from '@asol/core';
import { setCulture } from '@syncfusion/ej2-base';
import languages from './core/model/languages.json';

export let InjectorInstance: Injector;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss',
    '../assets/fonts/geomanist.scss',
    '../assets/fonts/roboto.css',
  ],
  encapsulation: ViewEncapsulation.Emulated,
})
export class AppComponent {
  constructor(private trans: TranslationService) {
    // Poriesime nastavenie locale.
    // Je potrebne dat pozor na to, ze CLDR (a syncfusion vyuzivajuce CLDR) ocakava kod
    // jazyka dvojznakovy a 'sk' atd. a nie 'sk-SK', preto musime prematovat.
    const lang = languages.filter(
      (l) =>
        l.locale == navigator.language ||
        l.short == navigator.language ||
        navigator.language.startsWith(l.short)
    )[0]?.short;
    this.trans.setLocale(lang ?? 'en');
    setCulture(lang ?? 'en');
  }
}
