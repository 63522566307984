<div class="content d-flex justify-content-center">
  <div>
    <app-main-page></app-main-page>
  </div>
  <div class="form-container">
    <app-language-switcher></app-language-switcher>
    <div class="form-header">
      {{ trans.get(TRANS.FORGOT_PASSWORD, 'ForgottenPassword') }}
    </div>
    <div class="form-description">
      {{ trans.get(TRANS.FORGOT_PASSWORD, 'ForgottenPasswordDecription') }}
    </div>
    <div class="form">
      <form [formGroup]="form">
        <div class="w-100">
          <asol-input-field
            formControlName="email"
            [transKey]="TRANS.FORGOT_PASSWORD"
            labelKey="Email"
            placeholderKey="Email"></asol-input-field>
        </div>
        <div class="row buttons">
          <!-- <asol-button class="me-1 login-button">Registrovať sa</asol-button> -->
          <button type="button" class="btn btn-primary btn-lg btn-block">
            {{ trans.get(TRANS.FORGOT_PASSWORD, 'RefreshPasswordButton') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div id="progress-container"></div>
