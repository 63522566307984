import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { TranslateService } from '@ngx-translate/core';
import {
  createSpinner,
  hideSpinner,
  showSpinner,
} from '@syncfusion/ej2-popups';

import { RegistrationService } from 'src/app/core/service/registration.service';
import { ValidateClientResponse } from '../../model/validate-client-response.interface';

@Component({
  selector: 'app-validate-client',
  templateUrl: './validate-client.component.html',
  styleUrls: ['./validate-client.component.scss'],
})
export class ValidateClientComponent implements OnInit, AfterViewInit {
  public validationCode: string;
  public validated = false;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public registrationService: RegistrationService
  ) // public translate: TranslateService
  {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.validationCode) {
        this.validationCode = params.validationCode;
      }
    });
  }

  ngAfterViewInit(): void {
    createSpinner({ target: document.getElementById('progress-container') });
    showSpinner(document.getElementById('progress-container'));
    if (this.validationCode) {
      this.registrationService
        .validateDemoClient(this.validationCode)
        .subscribe(
          (resp) => {
            this.validated = true;
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: { validationCode: null },
              queryParamsHandling: 'merge',
            });
            window.location.href = (
              resp as ValidateClientResponse
            )?.redirectToLink;
            hideSpinner(document.getElementById('progress-container'));
          },
          (err) => {
            hideSpinner(document.getElementById('progress-container'));
          }
        );
    } else {
      hideSpinner(document.getElementById('progress-container'));
    }
  }
}
