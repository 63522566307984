<!-- <div class="header-section" id="header">
  <ul class="header-list">
    <div class="bg"></div>
    <li>
      <div style="display: inline-block; width: 215px">
        <span class="asseco-solutions-logo"></span>
      </div>
      <button
        class="language-button"
        ejs-dropdownbutton
        (select)="chooseLangage($event)"
        [items]="languages"
        content="{{ this.translate.instant('LANGUAGE.LANGUAGE') }}"></button>
    </li>
  </ul>
</div> -->
<div class="base-style">
  <router-outlet></router-outlet>
</div>
