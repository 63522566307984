// import { TranslateService } from '@ngx-translate/core';

export enum ReturnCodeEnum {
  EVERYTHING_OK = 0,
  SOMETHING_WENT_WRONG = 1,
  FAILED_TO_WORK_WITH_HASH = 2,
  FAILED_CONVERSION_BETWEEN_JSON_AND_OBJECT = 3,
  USER_ALREADY_VALIDATED = 4,
  USER_EMAIL_ADDRESS_ALREADY_EXISTS = 5,
}
export class ErrorTranslationUtils {
  static trasnslateErrorState(
    stateId: ReturnCodeEnum
    // translate: TranslateService
  ): string {
    return '';
    // switch (stateId) {
    //   case ReturnCodeEnum.EVERYTHING_OK:
    //     return translate.instant('RETURN_CODE.EVERYTHING_OK');
    //   case ReturnCodeEnum.FAILED_TO_WORK_WITH_HASH:
    //     return translate.instant('RETURN_CODE.FAILED_TO_WORK_WITH_HASH');
    //   case ReturnCodeEnum.FAILED_CONVERSION_BETWEEN_JSON_AND_OBJECT:
    //     return translate.instant(
    //       'RETURN_CODE.FAILED_CONVERSION_BETWEEN_JSON_AND_OBJECT'
    //     );
    //   case ReturnCodeEnum.USER_ALREADY_VALIDATED:
    //     return translate.instant('RETURN_CODE.USER_ALREADY_VALIDATED');
    //   case ReturnCodeEnum.USER_EMAIL_ADDRESS_ALREADY_EXISTS:
    //     return translate.instant(
    //       'RETURN_CODE.USER_EMAIL_ADDRESS_ALREADY_EXISTS'
    //     );
    //   case ReturnCodeEnum.SOMETHING_WENT_WRONG:
    //   default:
    //     return translate.instant('RETURN_CODE.SOMETHING_WENT_WRONG');
    // }
  }
}
