export const TRANS = {
  GENERAL: 'app-general',
  LOGIN: 'app-login-screen',
  REGISTER: 'app-register-client',
  CREATE_PASSWORD: 'app-create-password',
  FORGOT_PASSWORD: 'app-forgot-password',
  TENANT_PICKER: 'app-tenant-picker',
  MAIN_PAGE: 'app-main-page',
  MAIN_PAGE_CWO: 'app-main-page-cwo',
  LANGUAGE_SWITCHER: 'app-language-switcher',
};
