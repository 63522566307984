import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslationService } from '@asol/core';
import { APP_CONSTANT } from '../../constants/app.constant';
import { TRANS } from '../../constants/translation.constant';
import translation from './create-password.translation.json';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss'],
})
export class CreatePasswordComponent {
  TRANS = TRANS;

  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public trans: TranslationService
  ) {
    this.trans.initialize(TRANS.CREATE_PASSWORD, translation);
    this.form = this.formBuilder.group({
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(APP_CONSTANT.PASSWORD_PATTERN),
        ],
      ],
      passwordConfirmation: [
        '',
        [
          Validators.required,
          Validators.pattern(APP_CONSTANT.PASSWORD_PATTERN),
        ],
      ],
    });
  }
}
