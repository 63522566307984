import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { InfoText } from '../model/info-text.interface';
import { ApiDataService } from '../util/api-data-service.service';
import { Observable } from 'rxjs';
import { ValidateClientResponse } from '../model/validate-client-response.interface';
import { RegisterClientRequest } from '../model/register-client-request.interface';
import { RegisterClientResponse } from '../model/register-client-response.interface';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  constructor(
    protected httpClient: HttpClient,
    private apiDataService: ApiDataService
  ) {}

  validateDemoClient(
    validationInfo: string
  ): Observable<ValidateClientResponse> {
    return this.apiDataService.get<ValidateClientResponse>(
      `registration-service/validate-demo-client?validationInfo=${validationInfo}`
    );
  }

  public registerDemoClient(
    req: RegisterClientRequest
  ): Observable<RegisterClientResponse> {
    return this.apiDataService.postData<RegisterClientResponse>(
      'registration-service/demo-client',
      req
    );
  }

  getInfoTexts(): Observable<InfoText[]> {
    return this.apiDataService.get<InfoText[]>(
      `registration-service/info-texts`
    );
  }
}
