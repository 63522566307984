import { Injectable, ErrorHandler } from '@angular/core';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { HttpErrorResponse } from '@angular/common/http';
// import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler extends ErrorHandler {
  constructor() {
    // public translate: TranslateService
    super();
  }

  handleError(error: unknown) {
    console.error('ERROR_HANDLER: ', error);
    let msg = '';
    if (error instanceof HttpErrorResponse) {
      msg = (error as HttpErrorResponse).statusText;
    } else {
      // msg = this.translate.instant('RETURN_CODE.SOMETHING_WENT_WRONG');
    }
    DialogUtility.alert({
      // title: this.translate.instant('RETURN_CODE.WE_ARE_SORRY'),
      position: { X: 'center', Y: 'center' },
      content: msg,
      cssClass: 'warning-dialog-class',
      showCloseIcon: true,
      closeOnEscape: true,
      animationSettings: { effect: 'Zoom' },
      zIndex: 99999999999999,
    }).show();
  }
}
