import { TRANS } from './core/constants/translation.constant';
import { TokenRequiredService } from './core/service/token-required.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import { DialogModule, TooltipModule } from '@syncfusion/ej2-angular-popups';
import {
  DatePickerModule,
  DateTimePickerModule,
} from '@syncfusion/ej2-angular-calendars';
import { ScheduleModule } from '@syncfusion/ej2-angular-schedule';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import {
  SidebarModule,
  MenuModule,
  TabModule,
  ToolbarModule,
  AccordionModule,
} from '@syncfusion/ej2-angular-navigations';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { DropDownButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ButtonModule,
  CheckBoxModule,
  ChipListModule,
  RadioButtonModule,
} from '@syncfusion/ej2-angular-buttons';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
  CZ,
  EN,
  LANGUAGES,
  SK,
  DEFAULT_LANGUAGE,
  TranslationService,
  API_BASE_URI,
  AsolCoreModule,
} from '@asol/core';
import { environment } from 'src/environments/environment';
import { AsolControlModule, AsolLanguageSelectModule } from '@asol/controls';
import { registerLicense } from '@syncfusion/ej2-base';
import translation from '../app/app.translation.json';

@Injectable()
export class ParameterHashLocationStrategy extends HashLocationStrategy {
  prepareExternalUrl(internal: string): string {
    return window.location.search + super.prepareExternalUrl(internal);
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    CommonModule,
    BrowserModule,
    SidebarModule,
    MenuModule,
    GridModule,
    DropDownButtonModule,
    TabModule,
    TextBoxModule,
    DropDownListModule,
    ToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    ChipListModule,
    AccordionModule,
    AngularSvgIconModule,
    ButtonModule,
    CheckBoxModule,
    RichTextEditorModule,
    RadioButtonModule,
    ScheduleModule,
    DateTimePickerModule,
    DatePickerModule,
    TooltipModule,
    DialogModule,
    AsolLanguageSelectModule,
  ],
  exports: [],
  providers: [
    { provide: LocationStrategy, useClass: ParameterHashLocationStrategy },
    {
      provide: LANGUAGES,
      useValue: [CZ, SK, EN],
    },
    {
      provide: DEFAULT_LANGUAGE,
      useValue: EN,
    },
    TranslationService,
    {
      provide: LOCALE_ID,
      deps: [TranslationService],
      useFactory: (trans: { currentLocale: string }) => trans.currentLocale,
    },
    {
      provide: API_BASE_URI,
      useValue: environment.API_URL,
    },
    TokenRequiredService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private trans: TranslationService) {
    this.trans.initialize(TRANS.GENERAL, translation);
    registerLicense(environment.SyncfusionLicence);
  }
}
