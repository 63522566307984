import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Language, TranslationService } from '@asol/core';
import { TRANS } from '../../constants/translation.constant';
import { Company } from './models/company.interface';
import translation from './tenant-picker.translation.json';

@Component({
  selector: 'app-tenant-picker',
  templateUrl: './tenant-picker.component.html',
  styleUrls: ['./tenant-picker.component.scss'],
})
export class TenantPickerComponent implements OnInit {
  TRANS = TRANS;

  public form: FormGroup;

  companies: Company[] | null = [];

  constructor(
    private formBuilder: FormBuilder,
    public trans: TranslationService
  ) {
    this.trans.initialize(TRANS.TENANT_PICKER, translation);
    this.form = this.formBuilder.group({
      company: [''],
    });
  }

  ngOnInit(): void {
    this.companies = [{ name: 'Asseco Solutions' }];
    this.form.patchValue({ company: this.companies[0].name });
  }
}
