import { TokenRequiredService } from '../../service/token-required.service';
import { environment } from 'src/environments/environment';
import { APP_CODE } from './../../constants/app-code.constant';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { markForValidation, TranslationService } from '@asol/core';
import translation from './register-client.translation.json';
import { TRANS } from '../../constants/translation.constant';
import { AsolDialogComponent } from '@asol/controls';
import { UserService } from '../../service/user.service';
import { UserCreate } from './models/user-create.interface';
import { RegisterClientResponse } from '../../model/register-client-response.interface';
import { RETURN_MESSAGE } from '../../constants/return-message.constants';

@Component({
  selector: 'app-register-client',
  templateUrl: './register-client.component.html',
  styleUrls: ['./register-client.component.scss'],
})
export class RegisterClientComponent implements OnDestroy {
  @ViewChild('dialog')
  dialogService!: AsolDialogComponent;
  @ViewChild('problemDialog')
  problemDialog!: AsolDialogComponent;

  @ViewChild('overlay')
  spinner!: ElementRef;

  TRANS = TRANS;
  APP_CODE = APP_CODE;
  appCode = environment.AppCode;
  token: string = null;

  public form: FormGroup;
  public dialogForm: FormGroup;

  sendEmailTime = 30;
  problemMessage: string;

  constructor(
    private service: UserService,
    private formBuilder: FormBuilder,
    public trans: TranslationService,
    private renderer: Renderer2,
    private tokenRequiredService: TokenRequiredService
  ) {
    tokenRequiredService.token.subscribe((t) => (this.token = t));

    this.trans.initialize(TRANS.REGISTER, translation);

    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      bussinessNumber: [null],
      phone: [null],
      enabled: [true],
      gdpr: [false],
    });

    this.dialogForm = this.formBuilder.group({
      userName: [''],
      password: [''],
    });
  }

  ngOnDestroy(): void {
    this.tokenRequiredService.token.unsubscribe();
  }

  onRegisterUser(): void {
    markForValidation(this.form);
    if (!this.form.valid) {
      return;
    }

    this.sendEmailTime = 30;
    this.startTimer();

    this.renderer.setStyle(this.spinner.nativeElement, 'display', 'flex');

    const user = {
      userName: this.form.get('email').value,
      name: this.form.get('name').value,
      surname: this.form.get('surname').value,
      email: this.form.get('email').value,
      telephone: this.form.get('phone').value,
      enabled: this.form.get('enabled').value,
    } as UserCreate;

    this.service
      .create(
        {
          ...user,
        },
        this.token
      )
      .subscribe({
        next: (resp: RegisterClientResponse): void => {
          this.transformReturmMessage(resp);
          if (resp.code === 0) {
            this.form.reset();
            this.problemMessage = null;
            this.dialogService.show();
          } else {
            this.problemDialog.show();
          }
        },
        error: (error: HttpErrorResponse) => {
          alert(error.message);
        },
        complete: () => {
          this.renderer.setStyle(this.spinner.nativeElement, 'display', 'none');
        },
      });
  }

  onCloseDialog(): void {
    this.dialogService.hide();
  }

  startTimer() {
    setInterval(() => {
      if (this.sendEmailTime > 0) {
        this.sendEmailTime--;
      }
    }, 1000);
  }

  transformReturmMessage(rm: RegisterClientResponse) {
    switch (rm.code) {
      case RETURN_MESSAGE.EVERYTHING_OK:
        this.problemMessage = null;
        break;
      case RETURN_MESSAGE.USER_EMAIL_ADDRESS_ALREADY_EXISTS:
        this.problemMessage = this.trans.get(
          TRANS.REGISTER,
          'returnMessageUserEmailAddressAlreadyExists'
        );
        break;
      default:
        this.problemMessage = this.trans.get(
          TRANS.REGISTER,
          'returnMessageSomethingWentWrong'
        );
        break;
    }
  }
}
