<div class="overlay" #overlay>
  <div class="spinner-area">
    <img class="spinner" src="/assets/images/spinner.svg" alt="Spinner" />
  </div>
</div>
<div class="content">
  <app-main-page *ngIf="appCode == APP_CODE.CRM"></app-main-page>
  <app-main-page-cwo *ngIf="appCode == APP_CODE.CWO"></app-main-page-cwo>
  <div
    [ngClass]="
      appCode == APP_CODE.TITANGUESTREG ||
      appCode == APP_CODE.TITANGUESTREGCLOUD
        ? 'form-container form-container-titanguestreg'
        : 'form-container'
    ">
    <app-language-switcher></app-language-switcher>
    <div class="form-header">
      {{ trans.get(TRANS.REGISTER, 'Registration') }}
    </div>
    <div class="form-description">
      {{ trans.get(TRANS.REGISTER, 'RegistrationDescription') }}
    </div>
    <div class="form">
      <form [formGroup]="form">
        <div class="row w-100">
          <div class="col-6">
            <asol-input-field
              formControlName="name"
              [transKey]="TRANS.REGISTER"
              labelKey="Name"
              placeholderKey="Name"></asol-input-field>
          </div>
          <div class="col-6">
            <asol-input-field
              formControlName="surname"
              [transKey]="TRANS.REGISTER"
              labelKey="Surname"
              placeholderKey="Surname"></asol-input-field>
          </div>
        </div>
        <div class="row w-100">
          <asol-input-field
            formControlName="email"
            [transKey]="TRANS.REGISTER"
            [labelKey]="appCode == APP_CODE.CRM ? 'Email' : 'EmailAddress'"
            placeholderKey="Email"></asol-input-field>
        </div>
        <div class="row w-100" *ngIf="appCode == APP_CODE.CRM">
          <div class="col-6">
            <asol-input-field
              formControlName="bussinessNumber"
              [transKey]="TRANS.REGISTER"
              labelKey="BussinessNumber"
              placeholderKey="BussinessNumber"></asol-input-field>
          </div>
          <div class="col-6">
            <asol-input-field
              formControlName="phone"
              [transKey]="TRANS.REGISTER"
              labelKey="Phone"
              placeholderKey="Phone"></asol-input-field>
          </div>
        </div>
        <div
          class="row w-100"
          *ngIf="appCode == APP_CODE.CWO || appCode == APP_CODE.TITANGUESTREG">
          <div class="col-12">
            <asol-input-field
              formControlName="phone"
              [transKey]="TRANS.REGISTER"
              labelKey="Phone"
              placeholderKey="Phone"></asol-input-field>
          </div>
        </div>
        <div class="mt-5 mb-6">
          <asol-checkbox
            formControlName="gdpr"
            labelKey="GPDRConfirm"
            [transKey]="TRANS.REGISTER">
          </asol-checkbox>
        </div>
        <div class="row w-75 buttons mb-6">
          <button
            type="button"
            [disabled]="!form.valid || !form.get('gdpr').value"
            class="btn btn-primary btn-lg btn-block"
            (click)="onRegisterUser()">
            {{ trans.get(TRANS.REGISTER, 'RegistrationButton') }}
          </button>
        </div>
      </form>
    </div>
  </div>
  <div id="qasidacrm-smaller"></div>
</div>

<div id="progress-container"></div>

<asol-dialog
  #dialog
  width="40rem"
  headerText="{{ trans.get(TRANS.REGISTER, 'thankYouForRegistration') }}">
  <div class="sign-in-message">
    <div>
      <span class="bold">{{
        trans.get(TRANS.REGISTER, 'SignInMessageBold')
      }}</span>
      {{
        appCode != APP_CODE.TITANGUESTREG &&
        appCode != APP_CODE.TITANGUESTREGCLOUD
          ? trans.get(TRANS.REGISTER, 'SignInMessage')
          : trans.get(TRANS.REGISTER, 'SignInMessageTitanGuest')
      }}
    </div>
    <div class="mt-3">
      {{ trans.get(TRANS.REGISTER, 'EmailSent') }}
    </div>
  </div>
  <div class="d-flex justify-content-center mt-4">
    <div class="progress blue">
      <span class="progress-right">
        <span class="progress-bar"></span>
      </span>
      <span class="progress-left">
        <span class="progress-bar"></span>
      </span>
      <div class="progress-value">{{ sendEmailTime }}s</div>
    </div>
  </div>
  <div class="d-flex justify-content-center mt-3">
    <asol-button type="link" class="send-verification">{{
      trans.get(TRANS.REGISTER, 'ResendVerificationEmail')
    }}</asol-button>
  </div>
  <div class="d-flex justify-content-center mt-4">
    <asol-button (click)="onCloseDialog()">{{
      trans.get(TRANS.REGISTER, 'Continue')
    }}</asol-button>
  </div>
</asol-dialog>

<asol-dialog
  #problemDialog
  width="40rem"
  headerText="{{ trans.get(TRANS.REGISTER, 'errorOccured') }}">
  <div class="sign-in-message">
    <div>
      {{ problemMessage }}
    </div>
  </div>
</asol-dialog>
