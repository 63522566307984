<span class="activation-background">
  <div class="activation-text">
    <h1 *ngIf="validated == false && this.validationCode">
      <!-- {{ 'VALIDATE_CLIENT.YOUR_DEMO_ACCOUNT_IS_BEING_ACTIVATED' | translate }} -->
    </h1>
    <h1 *ngIf="validated == true && this.validationCode">
      <!-- {{ 'VALIDATE_CLIENT.DONE' | translate }} -->
    </h1>
    <h1 *ngIf="!this.validationCode">
      <!-- {{ 'VALIDATE_CLIENT.UNKNOWN_VALIDATION_INFORMATION' | translate }} -->
    </h1>
  </div>
</span>

<div id="progress-container"></div>
