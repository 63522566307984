import { HttpHeaders, HttpParams } from '@angular/common/http';
import { CONTENT_TYPE } from '../constants/content-type.constant';

export abstract class BaseDataService {
  protected createHttpParams(searchParams?: unknown): HttpParams {
    let parameters = new HttpParams();

    if (searchParams) {
      for (const [paramName, value] of Object.entries(searchParams)) {
        if (value === undefined || value === null) {
          continue;
        }

        if (value instanceof Array) {
          value.forEach(
            (v) => (parameters = parameters.append(paramName, v.toString()))
          );
        } else {
          if (typeof value === 'string') {
            parameters = parameters.append(paramName, value);
          } else if (typeof value === 'number') {
            parameters = parameters.append(paramName, value.toString());
          } else if (typeof value === 'boolean') {
            parameters = parameters.append(paramName, value.toString());
          } else if (value instanceof Date) {
            parameters = parameters.append(paramName, value.toISOString());
          } else if (typeof value === 'object' && value) {
            parameters = parameters.append(paramName, value.toString());
          } else {
            throw new Error('Unknown http parameter type');
          }
        }
      }
    }

    return parameters;
  }

  protected getHeaders(contentType?: string): HttpHeaders {
    const type = contentType ? contentType : CONTENT_TYPE.JSON;

    return (
      new HttpHeaders()
        // .set('Accept', type)
        .set('Content-Type', type)
    );
  }
}
