<div class="main-image-container">
  <div id="qasidacanteen"></div>
  <div id="caption">{{ trans.get(TRANS.MAIN_PAGE, 'Caption') }}</div>
  <div id="subcaption">
    {{ trans.get(TRANS.MAIN_PAGE, 'Description') }}
  </div>
  <div class="sys-grid-container">
    <div class="sys-grid-item"><div id="crm-panel"></div></div>
    <div class="sys-grid-item"><div id="service-panel"></div></div>
    <div class="sys-grid-item"><div id="rental-panel"></div></div>
    <div class="sys-grid-item"><div id="ai-panel"></div></div>
    <div class="sys-grid-item"><div id="canteen-panel"></div></div>
  </div>
</div>
