import { ErrorHandler, NgModule } from '@angular/core';
import { RegisterClientComponent } from './components/register-client/register-client.component';

import { CommonModule } from '@angular/common';

import { BrowserModule } from '@angular/platform-browser';
import { DialogModule, TooltipModule } from '@syncfusion/ej2-angular-popups';
import {
  DatePickerModule,
  DateTimePickerModule,
} from '@syncfusion/ej2-angular-calendars';
import { ScheduleModule } from '@syncfusion/ej2-angular-schedule';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import {
  SidebarModule,
  MenuModule,
  TabModule,
  ToolbarModule,
  AccordionModule,
} from '@syncfusion/ej2-angular-navigations';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { DropDownButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ButtonModule,
  CheckBoxModule,
  ChipListModule,
  RadioButtonModule,
} from '@syncfusion/ej2-angular-buttons';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ValidateClientComponent } from './components/validate-client/validate-client.component';
import { GlobalErrorHandler } from './global-error-handler';
import {
  AsolInputFieldModule,
  AsolButtonModule,
  AsolCheckboxModule,
  AsolDialogModule,
  AsolSelectFieldModule,
  AsolLanguageSelectModule,
} from '@asol/controls';
import { CreatePasswordComponent } from './components/create-password/create-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginScreenComponent } from './components/login-screen/login-screen.component';
import { TenantPickerComponent } from './components/tenant-picker/tenant-picker.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { MainPageCwoComponent } from './components/main-page-cwo/main-page-cwo.component';

@NgModule({
  declarations: [
    RegisterClientComponent,
    ValidateClientComponent,
    CreatePasswordComponent,
    ForgotPasswordComponent,
    LoginScreenComponent,
    TenantPickerComponent,
    MainPageComponent,
    LanguageSwitcherComponent,
    MainPageCwoComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    SidebarModule,
    MenuModule,
    GridModule,
    DropDownButtonModule,
    TabModule,
    TextBoxModule,
    DropDownListModule,
    ToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    ChipListModule,
    AccordionModule,
    AngularSvgIconModule,
    ButtonModule,
    CheckBoxModule,
    RichTextEditorModule,
    RadioButtonModule,
    ScheduleModule,
    DateTimePickerModule,
    DatePickerModule,
    TooltipModule,
    DialogModule,
    AsolInputFieldModule,
    AsolButtonModule,
    AsolCheckboxModule,
    AsolDialogModule,
    AsolSelectFieldModule,
    AsolLanguageSelectModule,
  ],
  exports: [
    RegisterClientComponent,
    ValidateClientComponent,
    CreatePasswordComponent,
    ForgotPasswordComponent,
    LoginScreenComponent,
    TenantPickerComponent,
    MainPageComponent,
  ],
  providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandler }],
})
export class CoreModule {}
