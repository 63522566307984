<div class="content d-flex justify-content-center">
  <div>
    <app-main-page></app-main-page>
  </div>
  <div class="form-container">
    <app-language-switcher></app-language-switcher>
    <div class="form-header">
      {{ trans.get(TRANS.LOGIN, 'Welcome') }}
    </div>
    <div class="form-description">
      {{ trans.get(TRANS.LOGIN, 'WelcomeDescription') }}
    </div>
    <div class="form">
      <form [formGroup]="form">
        <div class="w-100">
          <asol-select-field
            formControlName="loginType"
            [options]="loginTypes"
            displayProperty="text"
            valueProperty="type"
            [transKey]="TRANS.LOGIN"
            labelKey="LoginType"
            placeholderKey="LoginType"></asol-select-field>
        </div>
        <div class="row buttons">
          <button type="button" class="btn btn-primary btn-lg btn-block">
            {{ trans.get(TRANS.LOGIN, 'ContinueButton') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div id="progress-container"></div>
