import { APP_CODE } from '../constants/app-code.constant';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ApiDataService } from '@asol/core';
import { Observable } from 'rxjs';
import { REGISTRATION_ENDPOINT } from '../constants/registration-endpoint.constant';
import { RegisterClientResponse } from '../model/register-client-response.interface';
import { UserCreate } from '../components/register-client/models/user-create.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private baseUri = `${REGISTRATION_ENDPOINT.BASE}`;

  constructor(private service: ApiDataService) {}

  /**
   * Creates new user and stores in DB
   * @param user User entity to store @see {@link UserCreate}
   * @returns Identifier of the stored user
   */
  public create(
    user: UserCreate,
    token: string = null
  ): Observable<RegisterClientResponse> {
    let url: string = null;
    switch (environment.AppCode) {
      case APP_CODE.CRM:
        url = `${REGISTRATION_ENDPOINT.BASE}/${REGISTRATION_ENDPOINT.REGISTRATION}`;
        break;
      case APP_CODE.CWO:
        url = `${REGISTRATION_ENDPOINT.BASE}/${REGISTRATION_ENDPOINT.REGISTRATION_CWO_CUSTOMER}?t=${token}`;
        break;
      case APP_CODE.TITANGUESTREG:
        url = `${REGISTRATION_ENDPOINT.BASE}/${REGISTRATION_ENDPOINT.REGISTRATION_TITANGUESTREG}`;
        break;
      case APP_CODE.TITANGUESTREGCLOUD:
        url = `${REGISTRATION_ENDPOINT.BASE}/${REGISTRATION_ENDPOINT.REGISTRATION_TITANGUESTREG_CLOUD}?t=${token}`;
        break;
      default:
        throw Error('Unknown application code.');
    }
    return this.service.postData(url, user);
  }

  tokenValidate(token: string): Observable<boolean> {
    return this.service.getOne<boolean>(
      `${REGISTRATION_ENDPOINT.BASE}/${REGISTRATION_ENDPOINT.TOKEN_VALIDATE}?t=${token}`
    );
  }
}
